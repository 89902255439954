
// ==============================================

//        NAV PROJECT - MOBILE

// ==============================================


//  Nav - mobile
// -----------------------------------------------
.nav-project__select {
  padding: 2rem 2rem 1rem 2rem;
  position: relative;
  select {
    padding: 1rem 1rem;
    background-color: #005085;
    border: 2px solid $color-cyan;
    color: $color-cyan;
    font-size: 1.5rem;
  }
  &:after {
    content:'';
    display: block;
    width: 1.5rem; height: 1.5rem;
    position: absolute; top:2.75rem; right: 3.5rem;
    border-left: 2px solid $color-cyan;
    border-bottom: 2px solid $color-cyan;
    pointer-events: none;
    transform: rotate(-45deg);
    filter: drop-shadow(0 0 10px $color-cyan)
  }
}


.nav-project-mobile__list {
  @include list;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto 0 auto;
  max-width: 330px;
  padding: 2rem 1rem 2rem 1rem;
  // @include bd(white);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 60px;
    height: 1px;
    background-color: $color-sky-light;
    transform: translateX(-50%);
  }
}

.nav-project-mobile__item {
  width: 100%;
  // @include bd(red,0.5);
}

.nav-project-mobile__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 22px 20px;
  font-weight: $weight-bold;
  color: white;
  text-transform: uppercase;
  background-color: #5FC8D8;
  border-radius: $border-radius;
  transition: background-color $trans-duration;
  // @include bd(white,1);

  .nav-project-mobile__item:nth-last-of-type(n+2) & {
    margin-bottom: 20px;
  }

  &.is-active {
    display: none;
  }

  p {
    margin: 0;
    font-size: 22px;
  }
}

//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(medium up) {
  .nav-project-mobile {
    display: none;
  }
}
