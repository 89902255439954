// ==============================================

//        POP IN CONTACT

// ==============================================



.pop-contact {
  position: fixed;
  bottom: 60px;
  right: 60px;
  z-index: 999; //z('pop-contact');
  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  transition: opacity $trans-duration, visibility $trans-duration, transform $trans-duration;

  &.is-active {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: opacity $trans-duration, visibility $trans-duration, transform $trans-duration;
  }
}


//  Button
// -----------------------------------------------

.btn-pop-contact {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: $color-navy;
  transform: translate(-50%, -50%);
  transition: transform $trans-duration;

  // gradient
  &:before {
    @include overlay();
    z-index: -1;
    border-radius: 50px;
    background-image: radial-gradient(
      circle farthest-side at 80% 100%,
      #394299 10%, $color-navy 80%
    );
    opacity: 0;
    transition: opacity 0.5s;
  }

  &:hover:before {
    opacity: 1;
  }


  &.is-open {
    transform: translate(-50%, -50%) scale(0.8);

    .btn-pop-contact__icon-open {
      opacity: 0;
      transform: translateX(-50%) translateY(-50%) scale(0.7) rotate(25deg);
      transition-delay: 0s;
    }

    .btn-pop-contact__icon-notif {
      opacity: 0;
      transition-delay: 0s;
    }

    .btn-pop-contact__icon-close {
      opacity: 1;
      transform: translateX(-50%) translateY(-50%) scale(1) rotate(0);
      transition-delay: 0.1s;
    }
  }

  .btn-pop-contact__icon-open,
  .btn-pop-contact__icon-close {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    fill: white;
    transition: opacity $trans-duration, transform $trans-duration;
  }

  .btn-pop-contact__icon-open {
    width: 23px;
    height: 34px;
    transition-delay: 0.1s;
  }
  .btn-pop-contact__icon-close {
    width: 30px;
    height: 30px;
    opacity: 0;
    transform: translateX(-50%) translateY(-50%) scale(0.7) rotate(-25deg);
  }

  .btn-pop-contact__icon-notif {
    position: absolute;
    top: -3px;
    right: -3px;
    width: 30px;
    height: 30px;
    transition: opacity $trans-duration;
    transition-delay: 0.35s;
  }
}



//  Pop contact box
// -----------------------------------------------

.pop-contact__box {
  // display: none;
  position: absolute;
  bottom: 0;
  left: -300px;
  width: 300px;
  color: $color-navy;
  background-color: #F9F9FB;
  border-radius: $border-radius;
  box-shadow: 2px 2px 42px 0 rgba(black,0.4);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  transform-origin: bottom right;
  // transform: translateY(15px);
  transition: opacity $trans-duration, visibility $trans-duration, transform $trans-duration;
  transition-timing-function: ease-in;

  .pop-contact.is-open & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) scale(1);
    transition-timing-function: ease-out;
  }
}

.pop-contact__box-inner {
  padding: 22px 1rem 1rem;
}

.pop-contact__box-welcome {
  margin: 0 0 8px;
}

.pop-contact__box-infos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;

  p {
    margin: 0;
    font-size: 15px;
  }

  img {
    width: 80px;
  }
}

.form-contact {
  input,
  textarea {
    margin-bottom: 0.5rem;
  }

  button {
    padding: 10px 20px;
  }
}

.form-contact__feedback {
  margin: 8px 0 0;
  font-size: 16px;
  font-weight: 700;
  color: rgb(41, 190, 41);
}


//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(small only) {
  .pop-contact {
    bottom: 40px;
    right: 40px;
  }

  .btn-pop-contact {
    height: 50px;
    width: 50px;

    .btn-pop-contact__icon-open {
      width: 17px;
      height: 24px;
    }
    .btn-pop-contact__icon-close {
      width: 20px;
      height: 20px;
    }

    .btn-pop-contact__icon-notif {
      top: 0;
      right: 0;
      width: 15px;
      height: 15px;
    }
  }
}
