// ==============================================

//        BUTTONS

// ==============================================


//  Buttons
// -----------------------------------------------


.btn {
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  font-size: 23px;
  font-weight: $weight-bold;
  text-align: center;
  border-radius: $border-radius;
  color: white;
  background-color: $color-orange;
  overflow: hidden;

  // gradient
  &:before {
    @include overlay();
    z-index: -1;
    background-image: radial-gradient(
      circle farthest-side at 80% 100%,
      #F5971D 10%, $color-orange 80%
    );
    // #E88E1B 10%, $color-orange 80%
    opacity: 0;
    transform: translateX(180px);
    transition: opacity 0.5s, transform 0.5s;
    transition-timing-function: ease-in;
  }

  &:hover:before {
    opacity: 1;
    transform: translateX(0);
    transition-timing-function: ease-out;
  }

  &.--purple {
    background-color: $color-purple;
    &:before {
      background-image: radial-gradient(
        circle farthest-side at 80% 100%,
        $color-purple-light 10%, $color-purple 80%
      );
    }
  }
  
  &.--navy {
    background-color: $color-navy;
    &:before {
      background-image: radial-gradient(
        circle farthest-side at 80% 100%,
        $color-navy-light 10%, $color-navy 80%
      );
    }
  }
  
  &.--grey {
    background-color: #c3c5ca;
    color: $color-navy;
    // &:before {
    //   background-image: radial-gradient(
    //     circle farthest-side at 80% 100%,
    //     $color-navy-light 10%, $color-navy 80%
    //   );
    // }
  }
  &.--small {
    font-size: .8rem;
    padding: .5rem 1rem;
  }
}

button.btn {
  cursor: pointer;
}


// text smaller
.btn--navy {
  font-size: 20px;
  padding: 14px 20px;
  background-color: $color-navy;

  // gradient
  &:before {
    background-image: radial-gradient(
      circle farthest-side at 80% 100%,
      #2F3780 10%, $color-navy 80%
    );
  }
}



//  Buttons social
// -----------------------------------------------

.btn-social {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  font-size: 25px;    // set svg size (svg height = font-size)

  &:hover .icon {
    fill: $color-sky-light;
  }

  .icon {
    fill: white;
    transition: fill 0.3s;
  }
}
