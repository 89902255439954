// ==============================================

//        VARIABLES

// ==============================================

//  Colors
// -----------------------------------------------
$color-navy           : #332E82; //262A6C;
$color-navy-light     : #262A6C; //332E82
$color-orange         : #E84E1B;
$color-orange-light   : #F27349;
$color-yellow         : #0e070f;
$color-yellow-light   : #FFD424;
$color-cyan           : #66E5F8;
$color-sky            : #919BB6;
$color-sky-light      : #A7C2D2;
$color-light-gray     : #DFE1E8;

$color-purple         : #954DA0;
$color-purple-light   : #BE62CC;

$color-body-background : $color-navy;
$color-body-text       : white;

//  Fonts
// -----------------------------------------------
$font-plain : 'Roboto Condensed', sans-serif; // $weight-regular : 400,
$font-quote : 'Merriweather', serif;

// Font-weights
// $weight-thin:     200;
// $weight-light:    300;
$weight-regular:  400;
// $weight-medium:   500;
$weight-bold:     700;
// $weight-xbold:    800;
// $weight-black:    900;

// alias
$weight-normal: $weight-regular;


//  Size / Units
// -----------------------------------------------
// Container's maximum width
$max-width      : 1200px;
$border-radius  : 12px;
$trans-duration : 0.25s;
$smallmed-width : 750px;

:root {
  // --header-height      : 64px;
  --wrapper-padding    : 2rem;

  @media screen and (max-width: 640px) {
    --wrapper-padding    : 1rem;
  }
}
