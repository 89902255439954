
// ==============================================

//        HOME

// ==============================================

.home {
  background-color: $color-navy-light;
  // @include bg(red,1);
}

.home-visu__ratio {
  position: relative;
  padding-top: 51.7%;      // aspect-ratio
}



.home-visu__ratio-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: hidden;

  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.home-visu__img-wrapper {
  position: absolute;
  // overflow: hidden;
  top: 0;
  left: 60%;
  height: 100%;
  width: 40%;
  &.aos-animate .intro-desktop { clip-path: circle(70% at 85% 50%); }
  .intro-desktop {
    margin: 0; padding: 0;
    width: 100%; height: 100%;
    clip-path: circle(10% at 100% 50%);
    transition: 2000ms all ease-out;
  }
  img { 
    object-fit: (cover); width: 100%; height: 100%; 
    animation: glossy 10000ms infinite;
  }
  &:before {
    content:''; 
    border: 2px solid rgba($color-navy, 0.8);
    position: absolute; z-index: 333;
    top: 0; left: 0;
    transform: scale(1.5) translateX(15%);
    aspect-ratio: 1/1;
    height: 100%;
    border-radius: 50%;
    animation: grow 5000ms infinite;
  }
}

@keyframes glossy {
  0% { 
  }
  50% { 
      transform: scale(1.02) rotate(2deg);
  }
  10% { 
  }
}
@keyframes grow {
  0% { 
  }
  50% { 
      transform: scale(1.45) translateX(15%);
  }
  10% { 
  }
}


.home-visu__title {
  position: relative;
  z-index: 1;
  width: 100%;
  margin-top: 100px;
  margin-left: 2rem;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  line-height: .8;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    background-color: $color-purple;
    background-image: linear-gradient(90deg, $color-navy, $color-purple);
  }

  .row-1 { font-size: 80px;}
  .row-2 { font-size: 79px;}
  .row-3 { font-size: 105px;}
}
.home-visu__title-sub {
  color: $color-navy;
  font-size: 1.25rem;
  font-weight: normal;
  margin-left: 2rem;
}

//  MQ Breakpoints
// -----------------------------------------------

.intro-mobile { display: none; }

@media screen and (max-width: $smallmed-width) {
  .home {
    overflow: hidden;
  }
  .home-visu__ratio {
    position: relative;
    padding-top: 0;      // remove aspect-ratio
    height: 100vh;
  }

  .home-visu__ratio-inner {
    position: static; 
    .wrapper {
      height: 70% !important;
      justify-content: flex-end;
      .home-visu__title { 
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        align-items: center;
        margin: unset;
        transform: scale(.5) translateY(2rem);

        span { white-space: nowrap; line-height: 1;}
      }
      .home-visu__title-sub {
        width: 330px;
        margin: 0 auto;
        transform: translateY(-2rem);
        // margin-bottom: 2rem;
      }
    }
  }
  
  .home-visu__img-wrapper {
    top: unset;
    bottom: 0; left: 0;
    height: 30%; width: 100%;
    &:before { display: none;}
    .intro-mobile { display: block; margin: 0;}
    .intro-desktop { display: none;}
    // figure { clip-path: circle(70% at 50% 100%) !important; }
    // &:before {
    //   animation: unset;
    //   transform: unset;
    //   top: 0; z-index: -1;
    //   height: unset; width: 100%;
    //   transform-origin: center center;
    //   transform: scaleX(1.25);// translateY(33%);
    //   // margin-top: -2rem;
    //   // display: none;
    // }
    // border: 1px solid red;
  }

  .home-visu__ratio-inner h2 { text-align: center;}
  .home-visu__video {
    display: none;
  }

  .home-visu__title {
    // max-width: 370px;
    margin-top: 360px;
    margin-bottom: 50px;
    padding: 0 2rem;
    margin-left: 2rem;
    line-height: 1.3;
    text-align: center;
    // @include bd(red,0.8);
  }

  .header > .wrapper { padding-top: 1rem;}
  .logo-link { margin-bottom: 1rem !important;}

  .header.is-sticky.is-small .wrapper {
    transform: translateY(-5px);
  }

  // hook de fleinard...
  main { overflow: hidden;}
  .project.--list h3 { 
    font-size: 1.7rem !important; padding: 0 .5rem;
    br { display: none;}
  }
}
