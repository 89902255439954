
// ==============================================

//        CALL TO ACTION

// ==============================================


.cta {
  text-align: center;
  background-image: linear-gradient(180deg, #DFE1E8 0%, #fff 100%);
  position: relative;
  border-bottom-left-radius: 120px;
  // &:before {
  //   @include overlay();
  //   background-image: url('../images/bg-star.png');
  //   background-repeat: no-repeat;
  //   background-position: bottom center;
  //   background-size: cover;
  // }
  
  .wrapper {
    padding: 4rem 2rem;
    color: $color-navy;
  }

  h2 {
    margin-left: auto;
    margin-right: auto;
    // max-width: 830px;
    margin-bottom: 1rem;
  }

  p {
    max-width: 880px;
    margin: 0 auto;
    font-size: 20px;
    margin-bottom: 2rem;

    span {
      color: $color-yellow;
    }
  }
}



//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(small only) {
  .cta .wrapper {
    padding: 40px 1rem;
  }
}
