

// ==============================================

//        PROJECT

// ==============================================


.project {
  position: relative;
  text-align: center;
  
 
  
  &.--intro {
    padding-bottom: 4rem;
    background: linear-gradient(0deg, #3B3B93 0%, $color-navy 100%);
    background-image: url(../images/img_fond.png);
    background-repeat: no-repeat;
    background-position: left 80%;
    h2 { margin-bottom: 0;}

    &:before {
      content:'';
      width: 120px; height: 120px;
      position: absolute; bottom: 0; right: 0;
      background-image: url('../images/angle-white.svg');
      overflow: hidden;
    }
    .griddy {
      text-align: left;
      gap: 4rem;
      margin-top: 2rem;
    }
    .wrapper.--intro {
      padding-bottom: 4rem;
    }
    .wrapper.--invest {
      background-color: #fff;
      color: $color-navy;
      border-radius: 2rem;
      max-width: 980px;
      padding: 2rem;
      h3 { margin: 0; text-transform: uppercase;}
      p strong { color: $color-purple; }
      u {
        background-color: $color-purple;
        color: #fff;
        padding: 0 .25rem;
        border-radius: .3rem;
        text-decoration: none;
        font-weight: bold;
      }
      .griddy > * {
        position: relative;
        &:nth-child(1) { flex: 0 0 40%;}
        &:not(:last-child):after {
          content:'';
          position: absolute; top:0; right: -2rem;
          width: 1px; height: 100%;
          background: linear-gradient(0, #fff 0%, $color-purple 30%, $color-purple 70%, #fff 100%);
        }
      }
    }
    .wrapper.--quote {
      max-width: 820px;
      display: flex;
      margin-bottom: 2rem;
      align-items: center;
      figure {
        margin: 0;
        position: relative;
      }
      quote {
        text-align: left;
        display: block;
        font-size: 1.25rem;
        font-style: italic;
        font-weight: 700;
        &:before {
          content:'»';
          position: absolute;
          top: -1.5rem; left: -2rem;
          font-style: normal;
          font-size: 3rem; transform: scaleX(-1);
        }
      }
      figcaption {
        margin-top: 1rem;
        text-align: left;
        font-size: .8rem;
        strong { color: $color-purple-light; font-size: 1.25rem;}
      }
      img { max-width: 340px;}
    }
    .project-slide__video {
      max-width: 570px;
      margin: 0 auto;
      margin-top: 2rem;
      margin-bottom: 4rem;
      position: relative;
      iframe {
        border-radius: 30px;
        overflow: hidden;
      }
      &::before,
      &:after {
        content:'';
        width: 100%; height: 100%;
        
        background-image: url(../images/gradient-corner.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;
      }
      &:before {
        position: absolute; left: -2rem; top: -2rem;
        transform: scale(-1);
      }
      &:after {
        position: absolute; left: 2rem; top: 2rem;
      }
    }
    .col-1 {
      position: relative;
      &:after {
        content:'';
        width: 100%; height: 100%;
        position: absolute; left: 2rem; top: 2px;
        background-image: url(../images/gradient-corner.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;
      }
      
    }
  }
  
  &.--list {
    color: $color-navy;
    padding-bottom: 4rem;
    background: linear-gradient(0deg, #DFE1E8 0%, #fff 100%);
    h3 { max-width: 1140px; margin: 0 auto 2rem auto; font-size: 2rem;}
    h2 { margin: 1rem 0 .5rem 0; line-height: 1;}
    p { margin-top: 0;}

    figure { 
      border-radius: 3rem; overflow: hidden;
      max-width: 440px;
      cursor: pointer;
    }
    .griddy {
      padding-bottom: 2rem;
      &:after {
        content:''; position: absolute;
        top:0; left: 50%;
        width: 2px; height: 100%;
        background: linear-gradient(0deg, transparent 0%, $color-navy 30%, $color-navy 70%, transparent 100%);
      }
    }
    //
    &.--selected {
      & > * > .griddy {
        max-width: 1024px;
        flex-direction: column;
        &:after { display: none; }
        & > * { flex: 0 0 100%; }
      }
    }
  }

  .wrapper__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: 1rem auto 0 auto;
    gap: 2rem;
    position: relative;
    & > *:first-child { 
      position: absolute;
      left: 0; top: 20px;
      svg { 
        width: 12px; height: 12px;
        margin-right: .25rem;
        transform: scale(-.85) translateY(1px);
      }
    }
  }
  .griddy {
    gap: 2rem;
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 910px;
    margin: 0 auto;
    & > * { flex: 0 1 50%;}
  }

  .wrapper {
    max-width: 100%; 
    padding: 50px 0 0 0;
    // @include bd(white,0.5);
  }
  h3 {
    &.--hide { display: none;}
  }
      
} 

.btn__show {
  &.--hide { display: none; }
}

.btn__give {
  //hoo huewai ???
  overflow: hidden;
}

.project-slide {
  display: none;
  &.--show {
    order: 1;
    display: block;
  }
  .wrapper {
    text-align: left;
    display: flex;
    padding: 0 0 4rem 0;
    gap: 5rem;
    .intro {
      flex: 1 1 40%;
      .objectif {
        margin-top: 2rem;
        padding: 2rem;
        background: linear-gradient(0deg, rgba(223, 225, 232, 0) 0%, #DFE1E8 100%);
        border-radius: 1rem;
        h3 { 
          margin-bottom: 1rem;
          font-size: 1.25rem;
          line-height: 1;
          color: var(--current-color);
        }
      }
    }
    .contents {
      flex: 1 1 60%;
      padding-top: 1rem;
      position: relative;
      &:before {
        position: absolute;
        top:0; left: -2.5rem;
        width: 2px; height: 100%;
        content:'';
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, var(--current-color) 30%, var(--current-color) 70%, rgba(255,255,255,0) );
      }
      .content__nav {
        list-style: none;
        margin: 0 0 1rem 0; padding: 0;
        display: flex;
        li {
          margin-right: 1rem;
          border: 2px solid var(--current-color);
          color: var(--current-color);
          padding: .5rem 1rem;
          border-radius: 1rem;
          position: relative;
          display: flex;
          user-select: none;
          font-weight: 700;
          & > * { pointer-events: none; }
          .icon {/*  */
            width: 24px; height: 24px;
            margin-right: .5rem;
            &.--open { display: none; }
            &.--close { fill: var(--current-color); }
          }
          &:not(.--active) {
            cursor: pointer;
          }
          &.--active {
            // pointer-events: none;
            background: var(--current-color);
            color:#fff;
            .icon.--open { display: block; }
            .icon.--close { display: none; }
          }
          // &:hover:not(.--active) {
          //   .icon.--open { display: block;}
          //   .icon.--close { display: none;}
          // }
        }
      }
      .content {
        display: none;
        &.--active { display: block;}
      }
      .project___video {
        border-radius: 30px;
        overflow: hidden;
      }
    }
  }
}

.project__thumb {
  order:2;
  .metas_wrap a { display: none; }
  &.--hide { display: none;}
  &.--in-row {
    width: 720px;
    margin: 0 auto;
    display: flex;
    & > * { order: 2;}
    figure { order: 1; flex: 0 1 400px;}
    .metas_wrap {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      a { display: block;}
    }
    & > a { display: none; }
  }
  figure {
    position: relative;
    &:hover {
      &:before { transform: scale(1.5);}  
    }
    &:before {
      transition: 300ms all cubic-bezier(.47, 0, .74, .71);
      content:'▶'; display: flex;
      align-items: center; justify-content: center;
      font-size: .8rem; padding:2px 0 0 2px;
      color:#fff; background-color: var(--current-color);
      border-radius: 50%; width: 48px; height: 48px;
      position: absolute; top:calc(50% - 24px); left:calc(50% - 24px);
    }
    & > * { pointer-events: none;}
  }
}



.picto-play {
  width: 80px;
  height: 80px;
  transition: transform 0.25s;
  pointer-events: none;
}

.embed-container { 
  z-index: 222;
  @include aspect-ratio(16/9);
} 






 




//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(large down) {
   

}

@include breakpoint(medium down) {
    
}

@include breakpoint(small only) {
   .wrapper.--intro {
    padding-left: 1rem;
    padding-right: 1rem;
    .griddy {
      flex-direction: column;
      gap: 0;
      .col-1, .col-2 {
        // padding: 0 2rem;
        &:after { display: none; }
      }
    }
    .project-slide__video {
      margin: 2rem .5rem;
      &:before, &:after { display: none; }
    }
   }
   .wrapper.--invest {
    width: calc(100% - 1rem);
    .griddy {
      flex-direction: column;
      gap: 1rem;
      margin-top: 0;
    }
   }
   .wrapper.--quote {
      flex-direction: column;
      figure:nth-child(1) { 
        order:2; margin-top: 2rem !important;
        padding-left: 3rem;
        padding-right:2rem;
      }
   }

   .project.--intro {
    &::before { display: none; }
   }

   .project.--list {
    .griddy {
      flex-direction: column;
      &:after { display: none;}
    }
   }

   .project-slide {
    .intro { text-align: center;}
    .wrapper {
      padding: 0 1rem;
      flex-direction: column;
      gap: 1rem;
      .intro, .contents { flex: unset;}
      .objectif {
        padding: 1rem 1rem 0 1rem !important;
        text-align: center;
      }
      .content__nav {
        justify-content: center;
      }
      .contents {
        // text-align: center;
      }
    }
   }
  .project__thumb.--in-row {
    background: linear-gradient(180deg, #fff 0%, #DFE1E8 100%);
    width: 100% !important;
    flex-direction: column;
    .metas_wrap { 
      align-items: center;
      h2 { text-align: center;}
      a { display: none; }
    }
    & > * { order: unset;}
    figure { flex: unset;}
    & > a { display: inline-flex; order: 4; width: fit-content; margin: 0 auto; }
  }

  .wrapper__buttons {
    flex-direction: column;
    & > * { position: unset !important; left: unset !important; top: unset !important;}
  }
}

