
// ==============================================

//        EXAMPLES

// ==============================================


.bg-star {
  position: relative;
  // @include bg();
  
  // &:before {
  //   @include overlay();
  //   background-image: url('../images/bg-star.png');
  //   background-repeat: no-repeat;
  //   background-position: top center;
  // }
  background:  $color-navy-light;
  // background: linear-gradient(to bottom, #000E27 50%, #15153E 100%);
}

.example {
  position: relative;
  text-align: center;

  .wrapper {
    padding: 80px 0 20px;

    // @include bd(teal,1,1);
  }

  h2 {
    margin-bottom: 35px;
    font-weight: $weight-regular;
  }

  // .btn {
  //   display: none;
  // }
  // @include bd(cyan,1);
}


.example-wave {
  position: absolute;
  top: -121px;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 130px;
  transform: translateX(-50%);
  overflow: hidden;
}

.example-wave__icon {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 400px;
  height: 130px;
  transform: translateX(-50%);
  fill: $color-navy-light;
}

.btn-example {
  position: relative;
  padding: 1rem 2rem;

  &:hover .icon {
    transform: translateY(3px);
    fill: $color-yellow;
  }

  .icon {
    position: relative;
    width: 26px;
    height: 14px;
    fill: white;
    transition: fill $trans-duration, transform $trans-duration;
  }
}



//  rows
// -----------------------------------------------

.example-row {
  position: relative;
  padding-bottom: 50px;

  // separator between row
  &:first-of-type {
    margin-bottom: 50px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $color-purple-light;
    }
  }

  h3 {
    width: 110px;
    margin: 0;
    margin-left: auto;
    padding-right: 30px;
    font-size: 25px;
    text-align: left;
    // @include bd(teal,1);
  }

  &:first-of-type h3 {
    font-size: 48px;
  }
}


.example-row__case {
  display: flex;
  align-items: center;
  // @include bd(blue,0.8);
}

.example-row__case-graph {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 175px;
  width: 175px;
  margin-right: 30px;
  font-size: 18px;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 175px;
    height: 175px;
    filter: drop-shadow(0 0 12px rgba($color-purple-light,1));
  }

  strong {
    font-size: 29px;
    color: $color-purple-light;
  }
}

.example-row__case-texts {
  // @include bd(teal,1);
  text-align: left;

  p:first-child {
    margin: 0 0 1rem;
    font-size: 17px;
  }
  p:last-child {
    margin: 0;
    font-size: 22px;

    strong {
      color: $color-purple-light;
    }
  }
}



//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(large down) {
  .example .wrapper {
    padding: 80px 2rem 20px;
  }

  .example-row h3 {
    width: unset;
    margin-left: 0;
    margin-bottom: 2rem;
    padding-right: 0;
    text-align: center;
  }

  .example-row__case {
    justify-content: center;
  }
}

@include breakpoint(medium down) {
  .example-row__case {
    flex-direction: column;
    justify-content: center;
  }

  .example-row__case-graph {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .example-row__case-texts {
    text-align: center;
  }
}

@include breakpoint(small only) {
  .example .wrapper {
    padding: 80px 1rem 20px;
  }

  .example-row .column:last-child {
    display: none;
  }

  .example .btn {
    display: inline-block;
    margin-bottom: 1rem;
  }
}
