
// ==============================================

//        FISCALITE

// ==============================================

.anchor-fiscal {
  position: absolute;
  left: 0;
  top: -80px;

  @include breakpoint(small only) {
    top: -65px;
  }
}


.anchor-calcul {
  position: absolute;
  left: 0;
  top: -230px;

  @include breakpoint(small only) {
    top: -215px;
  }
}



.fiscal {
  position: relative;
  background-color: #f7f7fa;

  .wrapper {
    padding: 60px 0 130px;
    text-align: center;
    // @include bd();
  }

  h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    padding: 0 2rem;
    margin-bottom: 70px;
    color: $color-navy-light;
  }
}


.fiscal-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
  color: $color-navy-light;

  // separator
  &:nth-last-child(n+2):after {    // count from last element
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 160px;
    background-color: $color-navy-light;
    transform: translateY(-50%);
  }

  h3 {
    // @include bg();
    margin: 0 0 5px;
    font-size: 38px;
    color: $color-navy;
  }

  .fiscal-item__subtitle {
    margin: 0 0 15px;
    font-size: 22px;
    font-weight: $weight-bold;
  }

  .fiscal-item__digit {
    margin: 0 0 10px;
    font-size: 60px;
    font-weight: $weight-bold;
    color: $color-orange;
  }

  .fiscal-item__body {
    margin-top: 0;
  }
}



//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(large down) {
  .fiscal-item {
    padding: 0 2rem;
  }
}

@include breakpoint(medium down) {
  .fiscal-items {
    max-width: 760px;
  }
  .fiscal-item {
    margin-bottom: 3rem;

    // separator
    &:nth-child(2):after {
      display: none;
    }
  }
}

@include breakpoint(small only) {
  .fiscal h2 {
    padding: 0 1rem;
  }

  .fiscal-items {
    max-width: 400px;
  }
  .fiscal-item:nth-last-child(n+2) {
    padding-bottom: 2rem;

    // separator horizontal
    &:after {    // count from last element
      display: block;
      top: unset;
      bottom: 0;
      left: 50%;
      width: 160px;
      height: 1px;
      background-color: $color-navy-light;
      transform: translateX(-50%);
    }
  }
}
