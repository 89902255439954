
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999; //z(header);
  width: 100%;
  height: 134px;
  transition: all 0.4s;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    opacity: 0;
    transition: opacity 0.4s;
    background-color: #fff;
    box-shadow: 0 11px 22px 0 rgba($color-navy,0.7);
  }

  // header height reduced when scroll on desktop
  @media screen and (min-width: $smallmed-width) {
    &.is-small {
      height: 80px;
      background-color: $color-navy;

      &:before {
        opacity: 1;
      }

      .wrapper {
        transform: translateY(-20px);
      }
    }

    &.is-fixed {
      position: fixed;
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 30px 0 0 1rem;
    transition: transform 0.6s;
  }
}

// .header__left {
//   @include bd(white,1);
// }
//
// .header__right {
//   @include bd(white,1);
// }



.btn-header {
  display: inline-flex;
  align-items: center;
  font-size: 17px;
  font-weight: $weight-bold;
  line-height: 1.35;
  color: $color-navy;
  transition: color $trans-duration;

  &:first-child {  margin-right: 38px;  }

  &:hover {
    color: $color-orange;
  }

  &:focus {
    color: $color-navy;
  }

  .icon {
    margin-right: 12px;
    fill: $color-navy !important;
  }

  &.btn-header--calculator .icon {
    width: 30px;
    height: 40px;
    // font-size: 40px;
  }

  &.btn-header--calendar .icon {
    width: 36px;
    height: 40px;
    font-size: 40px;
  }

  span {
    max-width: 115px;
  }
}


.logo-link {
  // center logo in wrapper
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .logo {
    width: 200px;
    height: 51px;
  }
}


.btn-header-don {
  padding: 12px 12px;
  height: 100px;
  transition: height 0.4s;
  overflow: hidden;

  &:first-child {
    margin-right: 12px;
  }

  .btn-header-don__label-big {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // width: 100%;
    // transform: translateX(-50%) translateY(-50%);
    // transition: opacity 0.4s 0.3s, transform 0.4s 0.3s;

    span {
      display: block;
    }
    span:last-child {
      font-size: 17px;
    }
  }

  .btn-header-don__label {
    display: none;
    line-height: 1.1;
    opacity: 0;
    transform: translateY(15px);
    transition: opacity 0.4s, transform 0.4s;
  }

  // transition between two states
  .header.is-small & {
    height: 60px;
    padding: 12px 32px;

    .btn-header-don__label-big {
      display: none;
      // opacity: 0;
      // transform: translateX(-50%) translateY(calc(-50% - 15px));
      // transition: opacity 0.4s, transform 0.4s;
    }
    .btn-header-don__label {
      display: block;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.4s 0.3s, transform 0.4s 0.3s;
    }
  }
}

.btn-header-don-mobile {
  display: none;
}


//  MQ
// -----------------------------------------------


@include breakpoint(large down) {
  .header .wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .btn-header:first-child {
    margin-right: 28px;
  }

  .btn-header-don {
    // padding: 12px 16px;
    // height: 90px;
  }
}

@media screen and (max-width: 1060px) {
  .logo-link {
    position: static;
    transform: none;

    .logo {
      width: 170px;
    }
  }
}

@include breakpoint(medium down) {
  .header__right {
    display: none;
  }

  .btn-header-don-mobile {
    display: inline-flex;
    padding: 8px 30px;
    font-size: 20px;
    line-height: 1.15;
  }
}

@media screen and (max-width: $smallmed-width) {
  // header sticky
  .header.is-small {
    position: fixed;
    height: 65px;
    background-color: $color-navy;
    transition: unset;

    &:before {
      opacity: 1;
      transition: unset;
    }

    .wrapper {
      padding-left: 1rem;
      padding-right: 1rem;
      transform: translateY(-25px);
      transition: unset;
    }

    .btn-header {
      // @include bd(white,0.8);

      &:first-child {  margin-right: 8px;  }

      &.btn-header--calculator .icon {
        width: 26px;
      }

      &.btn-header--calendar .icon {
        width: 32px;
      }

      .icon {
        margin-right: 0;
      }

      span {
        display: none;
      }
    }

    .logo {
      width: 100px;
      height: 35px;
    }

    .btn-header-don-mobile {
      padding: 5px 14px;
      font-size: 17px;
    }
  }



  // header static
  .header:not(.is-small) {
    position: absolute;
    transition: unset;

    .wrapper {
      flex-direction: column;
      justify-content: center;
      transition: unset;
    }

    .header__left {
      order: 1;
    }

    .logo-link {
      margin-bottom: 30px;

      .logo {
        width: 200px;
      }
    }

    .btn-header-don-mobile {
      margin-bottom: 30px;
      padding: 10px 40px;
      font-size: 23px;
      line-height: 1.3;
    }

    .btn-header {
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .icon {
        margin-right: 0;
        margin-bottom: 10px;
      }

      span {
        max-width: 100px;
      }
    }
  }


}