
// ==============================================

//        CALENDAR

// ==============================================


.calendar {
  text-align: center;
  background-image: linear-gradient(180deg, $color-navy-light 0%, #3B3B93 100%);
  .wrapper {
    padding: 40px 0 60px;
    // @include bd(white,1,1);
  }

  h2 {
    margin-bottom: 1rem;
    // @include bd(red,1);
  }

  .intro {
    font-size: 20px;
    margin-bottom: 60px;
  }
}

.calendar-title {
  position: relative;
  margin-top: 40px;
  margin-bottom: 50px;
}




//  Calendar dates item
// -----------------------------------------------
.calendar-resident {
  margin: 2rem auto 0 auto;
  max-width: 720px;
  border: 1px solid $color-purple-light;
  border-radius: 12px;
  padding: .5rem 1rem;
  p {
    span { color:$color-purple-light}
  }
}

//  Calendar dates item
// -----------------------------------------------

.calendar-row {
  margin-bottom: 40px;
}

.calendar-date {
  position: relative;
  padding: 0 1rem;
  font-size: 18px;

  // separator
  &:not(:last-child):after {
    content: '';
    position: absolute;
    top: 30px;
    right: 0;
    width: 1px;
    height: 80px;
    background-color: $color-purple-light;
  }
}

.calendar-date__picto {
  position: relative;
  svg {
    width: 82px;
    height: 66px;
  }

  span {
    position: absolute;
    top: 30px;
    left: 50%;
    font-size: 21px;
    font-weight: $weight-bold;
    color: $color-purple-light;
    transform: translateX(-50%);
  }
}

.calendar-date__limit {
  margin: 1rem 0 0;
  color: $color-purple-light; 
  // @include bd(white,1);
}

.calendar-date__body {
  margin-top: 0;

  em {
    color: $color-light-gray;
  }
  // @include bd(white,1);
}


.calendar-mention {
  max-width: 600px;
  margin: 0 auto;
  font-size: 15px;
  color: $color-light-gray;
}


//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(large down) {
  .calendar .wrapper {
    padding: 40px 2rem 60px;
  }
}

@include breakpoint(medium down) {
  .calendar .intro {
    max-width: 700px;
    margin: 0 auto 60px;
  }
  .calendar-title {
    margin-top: 0;
  }
  .calendar-row {
    margin-bottom: 0;
  }
  .calendar-date {
    margin-bottom: 2rem;

    &:nth-child(2n):after {
      display: none;
    }
  }
}

@include breakpoint(small only) {
  .calendar-date:not(:last-child) {
    padding-bottom: 1rem;

    // separator
    &:after {
      display: block;
      top: unset;
      bottom: 0;
      left: 50%;
      width: 80px;
      height: 1px;
      transform: translateX(-50%);
    }
  }

}
