
// ==============================================

//        DOWNLOAD

// ==============================================


.download {
  background-color: #fff;
  .wrapper {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    margin-bottom: 0;
    margin-right: 40px;
    font-size: 29px;
    color: $color-navy-light;
  }

  .btn:nth-of-type(3) {
    display: none;
  }
}

.download-btns {
  display: flex;
  // @include bd(red);
}

.btn--download {
  padding: 12px 16px 12px 20px;

  .icon {
    font-size: 1.5em;
    margin-left: 1rem;
    fill: white;
  }

  // breakspace only on mobile
  br {
    display: none;
  }
}
.btn--download:first-of-type { margin-right: 30px;  }


//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(large down) {
  .download {
    .wrapper {
      padding: 50px 2rem;
      flex-direction: column;
      justify-content: center;
    }

    h2 {
      margin-right: 0;
      margin-bottom: 2rem;
      text-align: center;
    }
  }
}

@include breakpoint(medium down) {
  .download {
    .wrapper {
      padding-bottom: 100px;
    }
    .download-btns {
      flex-direction: column;
      align-items: center;
      max-width: 360px;
    }

    .btn--download {
      justify-content: space-between;
      width: 100%;
      text-align: left;

      // breakspace only on mobile
      br {
        display: inline;
      }
    }

    .btn--download {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}

@include breakpoint(small only) {
  .download {
    .wrapper {
      padding: 100px 1rem 100px;
    }
    .btn:nth-of-type(1) {
      margin-bottom: 2rem;
    }
    .btn:nth-of-type(2) {
      display: none;
    }
    .btn:nth-of-type(3) {
      display: inline-flex;
    }
  }
}
